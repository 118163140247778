import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ExampleImg from "../images/image1.png"
import { Container, Wrapper, ByLine, HeroImg, Para, Title, Related, InnerWrap } from "../styles/articleStyles"
import { ArticleImg, Article_Sm, ArticleTitle_Sm, ByLine_Sm, Goto} from "../styles/blogStyles"

const BlogsPage = () => (
  <Layout>
    <Seo title="Blogs" />
    <Container>
        <Wrapper>
            <ByLine>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine>
            <HeroImg src={ExampleImg}/>
            <Title>This is a Placeholder Title</Title>
            <Para>Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all help make it better.Inland Arts is a film project dedicated to bringing exposure to the arts culture in the Spokane, WA area and bringing attention to how we can all.</Para>
            <Related>Related</Related>
            <Para style={{width: "100%", textAlign: "center"}}>to this article</Para>
            <InnerWrap>
                <Article_Sm>
                    <ArticleImg src={ExampleImg}/>
                    <ArticleTitle_Sm>This is a Placeholder Title</ArticleTitle_Sm>
                    <ByLine_Sm>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Sm>
                </Article_Sm>
                <Article_Sm>
                    <ArticleImg src={ExampleImg}/>
                    <ArticleTitle_Sm>This is a Placeholder Title</ArticleTitle_Sm>
                    <ByLine_Sm>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Sm>
                </Article_Sm>
                <Article_Sm>
                    <ArticleImg src={ExampleImg}/>
                    <ArticleTitle_Sm>This is a Placeholder Title</ArticleTitle_Sm>
                    <ByLine_Sm>Written by <b>Dario Re</b> on <b>Jan 2022</b></ByLine_Sm>
                </Article_Sm>
            </InnerWrap>
        </Wrapper>
    </Container>
  </Layout>
)

export default BlogsPage
