import styled from 'styled-components'
import { Link } from "gatsby"

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    color: #202020;
`;

const Wrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 80%;
    margin-top: 50px;
    justify-content: center;
`;

const ByLine = styled.div`
    font-size: 18px;
    text-align: left;
    width: 65%;
    padding-bottom: 20px;
`;

const HeroImg = styled.img`
    height: 200px;
    width: 100%;
    margin: 0px;
    object-fit: cover;
`;

const Title = styled.div`
    font-size: 46px;
    text-align: left;
    width: 65%;
    padding-top: 60px;
    padding-bottom: 50px;

`;

const Para = styled.div`
    font-size: 20px;
    text-align: left;
    width: 65%;
    padding-top: 10px;
`;

const Related = styled.div`
    font-size: 46px;
    text-align: left;
    width: 65%;
    padding-top: 60px;
    text-align: center;
`;

const InnerWrap = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
`;

export {
    Container, 
    Wrapper,  
    ByLine,
    HeroImg,
    Title,
    Para,
    Related,
    InnerWrap,
}